// Include jQuery plugins
//require('jquery.sample');

// Import non-jQuery modules
//const sample = require('sample');

// Define wp_localize_script handles
/* global  */

(($) => {
    const wntrTheme = {
        body: $('body'),
        html: $('html'),
        document: $(document),

        /**
         * Initialise theme JS on page load
         */
        init: function () {
            // Do we have JS?
            $('html').removeClass('no-js');
            // Automatically call all methods that start with __, in the order they're defined
            for (const prop in wntrTheme) {
                if (prop.startsWith('__') && typeof wntrTheme[prop] === 'function') {
                    wntrTheme[prop].call();
                }
            }
        },

        __initClasses: () => {
            wntrTheme.body.find('.scholarships__decoration-wrapper').first().addClass('is-active');

            wntrTheme.body.find('.scholarship__content').first().slideToggle('fast');
            wntrTheme.body.find('.scholarship__title').first().addClass('is-active');
            
            wntrTheme.body.find('.recipient__title').first().addClass('is-active');
            wntrTheme.body.find('.recipient__content').first().slideToggle('fast');
        },

        __eventlisteners: () => {
            wntrTheme.body.find('.header__toggle').on('click', function() {
                $(this).toggleClass('is-active');
                wntrTheme.body.find('.menu').toggleClass('is-active');
                wntrTheme.body.toggleClass('no-scroll');
            });
            wntrTheme.body.find('.accordion__title').on('click', function() {                
                $(this).next('.accordion__content').slideToggle('fast');
                $(this).toggleClass('is-active');
            });
            wntrTheme.body.find('.scholarship__title').on('click', function() {        
                $(this).toggleClass('is-active');        
                $(this).parent().prev('.scholarships__decoration-wrapper').toggleClass('is-active');
                $(this).next('.scholarship__content').slideToggle('fast');
            });
            wntrTheme.body.find('.recipient__title').on('click', function() {
                $(this).toggleClass('is-active');
                $(this).parent().toggleClass('is-active');
                $(this).next('.recipient__content').slideToggle('fast');

            });
        },
    };
    $(document).ready(wntrTheme.init);
})(jQuery);